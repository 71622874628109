
/* header { */
    /* height: 69px; */
    
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center;    */
    /* text-align: center; */
/* }   */

header a {
    margin-left: 20px;
}

header nav {
    padding: 30px;
}
header nav a {
    padding: 30px;
}
 

 *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
   text-align: center;
 }

li{
    list-style: none;
}

a{
    color: #2d5c88;
    text-decoration: none;
    font-weight: bold;
}

.navbar{
    min-height:70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 50px;
}

.nav-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    
}

.nav-branding{
    font-size: 2rem;
}

.nav-link{
    transition: 0.7s ease;
}
.nav-link:hover{
    color: black;
}

.hamburger{
    display:none;
    cursor: pointer;
}

.bar{
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #2d5c88;
}

@media(max-width:768px){
    .hamburger{
        display: block;
    }

    .hamburger.active .bar:nth-child(2){
        opacity: 0;
    }
    .hamburger.active .bar:nth-child(1){
        transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu{
        position: fixed;
        Left: -100%;
        top: 70px;
        gap: 0;
        flex-direction: column;
        width: 100%;
        text-align: center;
        transition: 0.3s;
        background-color: white;
    }

    .nav-item{
        margin: 16px 0;
    }

    .nav-menu.active{
        Left: 0;

    }
}
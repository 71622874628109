#avatar {
   height: 20%;
   width: 20%;
}

body {
    background-image: url("/public/background2.jpg");
    background-size: cover;
    font-family: 'Quicksand', sans-serif;
}

.fade-in {
    animation-name: FadeIn;
    animation-duration: 1s;
    transition-timing-function: linear;
  }
  
  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-o-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-ms-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  @media(max-width:768px){
    #avatar {
      min-width: 200px;
    }

  }
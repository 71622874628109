#about-container {
    display: flex;
    
}

#headshot {
    margin-left: 50px;
    border-radius: 10px;
    border: black;
    border-style: solid;
    box-shadow: 8px 8px 8px 8px #888888;
    height: 500px;
    
  
}

#brand-statement {
    text-align: justify;
    /* margin-left: 30px;
    margin-right: 30px; */
    max-width: 500px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
}

@media(max-width:768px){
    #about-container {
        display: block;
    }

    #brand-statement {
        margin-top: 50px;
        margin: auto;
        margin-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
      
    }
    #headshot {
        margin: auto;
        width: 100%;
        height: auto;
    }
}
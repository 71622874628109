#footer {
    padding: 50px;
    background-color: #2d5c88;
    color: white;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
 
}

#footer img {
    margin: 20px;
}

#contact-link {
  color: white;
}
#contact-link:hover{
  border-bottom: solid;
  transition: ;
  
}
@media(max-width:768px){
    #footer img {
      min-width: 100px;
        
        
    }

  }